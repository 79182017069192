<template>
  <div class="v-stack gap-3 h-stretch">
    <div class="card light h-stretch">
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <PersonPicker
            v-model="user"
            :roles="roles.map((item) => item.value)"
          ></PersonPicker>
          <label class="text-left required">Roles</label>
          <div v-if="filteredRoles.length == 0">No Applicable roles</div>
          <div
            class="h-stack h-start gap-3"
            v-for="role in filteredRoles"
            :key="role.value"
          >
            <input type="checkbox" v-model="role.selected" />
            <label class="ma">{{ role.value }}</label>
          </div>
          <template v-if="checkIfFreelancer()">
            <label class="text-left">Cost (only for hired hands)</label>
            <input v-model="cost" type="number" />
            <label class="text-left">Gear Cost (only for hired hands)</label>
            <input v-model="gearCost" type="number" />
          </template>
          <label class="text-left">Description</label>
          <textarea v-model="description"></textarea>
          <div class="h-stack h-end">
            <button @click="submit()" class="submit">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PersonPicker from "@/components/PersonPicker.vue";

export default {
  props: ["id"],
  data() {
    return {
      users: [],
      user: "",
      roles: [],
      cost: 0,
      gearCost: 0,
      description: "",
    };
  },
  components: {
    PersonPicker,
  },
  computed: {
    filteredRoles() {
      const id = this.user;
      for (const user of this.users) {
        if (user._id == id) {
          const categories = user.categories.map((item) => item.name);
          return this.roles.filter((item) => categories.includes(item.value));
        }
      }
      return [];
    },
  },
  methods: {
    ...mapActions(["getUsers", "addStaffPostProduction", "getPricelistItems"]),
    submit() {
      if (this.user != "" && this.cost >= 0) {
        const roles = this.roles
          .filter((element) => element.selected)
          .map((element) => element.value);

        if (roles.length > 0) {
          this.addStaffPostProduction({
            user: this.user,
            project: this.id,
            cost: this.cost,
            gearCost: this.gearCost,
            roles: roles,
            comment: this.description,
          })
            .then(() => {
              this.$router.go(-1);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    checkIfFreelancer() {
      const id = this.user;
      for (const user of this.users) {
        if (user._id == id) {
          if (user.accessLevel == 0) {
            return true;
          } else {
            this.cost = 0;
            this.gearCost = 0;
            return false;
          }
        }
      }
    },
  },
  mounted() {
    this.getUsers()
      .then((users) => {
        this.users = users;
      })
      .catch((error) => {
        console.log(error);
      });

    this.getPricelistItems()
      .then((items) => {
        const services = Array.from(
          new Set(
            items
              .filter((item) => item.categoryMain == "PERSONÁL / SLUŽBY")
              .filter((item) => item.categorySub == "Postprodukce")
              .map((item) => item.name)
          )
        ).sort((a, b) => a.localeCompare(b, "cs"));

        this.roles = services.map((element) => {
          return {
            value: element,
            selected: false,
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>